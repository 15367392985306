<template>
  <div class="card">
    <div class="title">
      <el-select v-model="available" placeholder="请选择" @change="handleChange" clearable>
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <span></span>
      <span @click="handleOpenCardClick" class="other">开新卡</span>
    </div>
    <div class="card-item-list">
      <template v-if="userCardList.length > 0">
        <template :key="item" v-for="item in userCardList">
          <card-item
            @handleUpdateYue="handleUpdateYue"
            @cardDetailClick="cardDetailClick"
            :item="item"
            @handleCardConsumeClick="handleCardConsumeClick"
          ></card-item>
        </template>
      </template>
      <template v-else>
        <div class="empty-state-list">
          <el-empty description="暂无卡项数据" />
        </div>
      </template>
    </div>
    <div></div>
    <card-detail-dialog
      :cardId="currentCardId"
      :dialogVisible="detailDialogVisible"
      @changeDialogVisible="changeDetailDialogVisible"
    ></card-detail-dialog>

    <cardConsumeDialog
      v-if="consumeDialogVisible"
      :currentCardKind="currentCardKind"
      :currentCardNo="currentCardNo"
      :dialogVisible="consumeDialogVisible"
      @changeDialogVisible="changeConsumeDialogVisible"
    >
    </cardConsumeDialog>

    <updatechizhi
      v-if="yueDialogVisible"
      @refreshCard="refreshCard"
      @changeDialogVisible="changeYueDialogVisible"
      :dialogVisible="yueDialogVisible"
      :card="updateCard"
    ></updatechizhi>

    <selectShopDialog v-if="selectShopDialogVisible"
      :queryInfo="selectQueryInfo"
      :dialogVisible="selectShopDialogVisible"
      @changeDialogVisible="changeSelectShopDialogVisible">
    </selectShopDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import CardItem from './cpns/card-item/card-item.vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import { updateCommentState } from '@/service/main/customer'
import { tips } from '@/utils/tips'
import router from '@/router'
import cardDetailDialog from './cpns/card-detail-dialog/card-detail-dialog.vue'
import updatechizhi from '../../../../../../base-ui/update-chuzhi/edit-dialog'
import cardConsumeDialog from './cpns/card-consume-dialog/card-consume-dialog.vue'
import selectShopDialog from './cpns/select-shop-dialog/select-shop-dialog'

export default defineComponent({
  props: {

  },
  components: {
    CardItem,
    cardDetailDialog,
    updatechizhi,
    cardConsumeDialog,
    selectShopDialog
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const userId = computed(() => route.query.userId)
    const phone = computed(() => route.query.phone)
    const shopId = computed(() => route.query.shopId)
    const userCardList = computed(() => store.state.customer.userCardList)
    const available = ref(1)

    // console.log(userCardList.value)
    const currentCardId = ref()
    const cardDetailClick = id => {
      detailDialogVisible.value = true
      currentCardId.value = id
    }

    const is_available = ref('')
    const initPage = () => {
      store.dispatch('customer/getUserCardListAction', {
          userId: userId.value, 
          userCardStatus: available.value||undefined
        })
    }
    initPage()
    const options = [
      {
        value: null,
        label: '全部'
      },
      {
        value: 1,
        label: '使用中'
      },
      {
        value: 2,
        label: '已退款'
      },
      {
        value: 3,
        label: '已过期（可用）'
      },
      {
        value: 4,
        label: '已过期（不可用）'
      },
      {
        value: 5,
        label: '已用完'
      }
    ]
    const handleChange = val => {
      initPage()
    }

    const detailDialogVisible = ref(false)
    const changeDetailDialogVisible = flag => {
      detailDialogVisible.value = flag
    }

    let selectQueryInfo = ref({})
    const handleOpenCardClick = () => {
      selectShopDialogVisible.value = true
      selectQueryInfo.value = {
        userId:userId.value,
        phone:phone.value,
        shopId:shopId.value,
      }
    }
    const selectShopDialogVisible = ref(false)
    const changeSelectShopDialogVisible = (flag, queryInfo) => {
      selectShopDialogVisible.value = flag
      selectQueryInfo.value = queryInfo
    }
    // const handleOpenCardClick = () => {
    //   // router.push({
    //   //   path: '/main/order/openCard',
    //   //   query: {
    //   //     userId: userId.value,
    //   //     phone: phone.value,
    //   //     shopId: shopId.value
    //   //   }
    //   // })

    // }

    const yueDialogVisible = ref(false)
    const changeYueDialogVisible = flag => {
      yueDialogVisible.value = flag
    }

    const currentCardNo = ref('')
    const currentCardKind = ref(3)
    const consumeDialogVisible = ref(false)
    const changeConsumeDialogVisible = flag => {
      consumeDialogVisible.value = flag
    }

    const handleCardConsumeClick = obj => {
      currentCardNo.value = obj.cardNo
      currentCardKind.value = obj.kindId
      consumeDialogVisible.value = true
    }

    const updateCard = ref(null)
    const handleUpdateYue = item => {
      updateCard.value = item
      yueDialogVisible.value = true
    }

    const refreshCard = () => {
      initPage()
    }


    return {
      options,
      available,
      is_available,
      handleChange,
      currentCardKind,
      currentCardNo,
      handleCardConsumeClick,
      changeConsumeDialogVisible,
      consumeDialogVisible,
      updateCard,
      handleUpdateYue,
      changeYueDialogVisible,
      yueDialogVisible,
      refreshCard,
      cardDetailClick,
      currentCardId,
      userId,
      userCardList,
      handleOpenCardClick,
      detailDialogVisible,
      changeDetailDialogVisible,
      selectShopDialogVisible,
      changeSelectShopDialogVisible,
      selectQueryInfo
    }

  }
})
</script>

<style scoped lang="less">
.card {
  .card-item-list {
    display: flex;
    flex-wrap: wrap;

    // &>div:nth-child(2n) {
    //   &:deep(.card-item) {
    //     background-color: rgba(200, 124, 47, 1);
    //   }
    // }

    // &>div:nth-child(3n) {
    //   &:deep(.card-item) {
    //     background-color: rgba(121, 72, 234, 1);
    //   }
    // }
    & > div {
      &:deep(.card-item) {
        background-color: rgba(191, 146, 0, 1);
      }
    }
  }

  .title {
    padding-left: 16px;
    position: relative;
    margin: 30px 0 10px 0px;
    display: flex;
    justify-content: space-between;

    .other {
      color: rgba(42, 130, 228, 1);
      font-size: 12px;
      cursor: pointer;
    }
  }
}
</style>
